import directorySearchModalTemplate from "../../directory/directory-search-modal.component.html";

angular
    .module("kno2.settings")
    .controller("SettingsExportsCtrl", [
        "$rootScope",
        "$scope",
        "$q",
        "$uibModal",
        "NotificationService",
        "InteroperabilityService",
        "AddressesCacheFactory",
        "DocumentSourceService",
        "appConfig",
        function ($rootScope, $scope, $q, $uibModal, NotificationService, InteroperabilityService, AddressesCacheFactory, DocumentSourceService, appConfig) {
            $scope.brandDisplayName = appConfig.brandDisplayName;
            $scope.setForm = function (form) {
                $scope.form = form;
            };
            $scope.autoCompleteAddresses = autoCompleteAddresses;
            $scope.exportModeClick = function (value) {
                $scope.interoperabilitySettings.exportMode = value;
                if (value == "emr") {
                    $scope.isRestricted = $rootScope.hasUIResourceAction("forwardEmrPlanUpgrade", "planUpgradePrompt");
                    if ($scope.isRestricted) {
                        return $rootScope.promptUpgradeModal("forwardEmrPlanUpgrade");
                    }
                }
            };

            $scope.searchDirectory = searchDirectory;

            $scope.exportOptionClick = function (value) {
                $scope.interoperabilitySettings.exportOption = value;
                if (value == "xds-sd_cda") {
                    $scope.interoperabilitySettings.exportDirectoryType = null;
                    $scope.interoperabilitySettings.exportVisitDirectory = false;
                    $scope.interoperabilitySettings.exportFileFormatOption = null;
                }
            };
            $scope.forwardOptionClick = function (value) {
                if (!$scope.isRestricted) {
                    $scope.interoperabilitySettings.forwardOption = value;
                }
            };

            var clearInteroperabilitySettingsForOtherModes = function (value) {
                if (value == "print") {
                    $scope.interoperabilitySettings.exportOption = null;
                    $scope.interoperabilitySettings.exportDirectoryType = null;
                    $scope.interoperabilitySettings.exportVisitDirectory = false;
                    $scope.interoperabilitySettings.exportDirectory = null;
                    $scope.interoperabilitySettings.exportFileFormatOption = null;
                    $scope.interoperabilitySettings.directAddress = null;
                    $scope.interoperabilitySettings.forwardOption = null;
                    $scope.interoperabilitySettings.fromDirectAddress = null;
                }

                if (value == "emr") {
                    $scope.interoperabilitySettings.exportOption = null;
                    $scope.interoperabilitySettings.exportDirectoryType = null;
                    $scope.interoperabilitySettings.exportVisitDirectory = false;
                    $scope.interoperabilitySettings.exportDirectory = null;
                    $scope.interoperabilitySettings.exportFileFormatOption = null;
                }

                if (value == "save_as") {
                    $scope.interoperabilitySettings.directAddress = null;
                    $scope.interoperabilitySettings.fromDirectAddress = null;
                    $scope.interoperabilitySettings.forwardOption = null;
                }
            };

            $scope.xdsSdCdaExportSelected = function () {
                $scope.isRestricted = $rootScope.hasUIResourceAction("intakeDownloadXdsSdCdaButton", "planUpgradePrompt");
                if ($scope.isRestricted) {
                    return $rootScope.promptUpgradeModal("intakeDownloadXdsSdCdaButton");
                }
                return $scope.exportOptionClick("xds-sd_cda");
            };

            $scope.exportDirectoryTypeClick = function (value) {
                $scope.interoperabilitySettings.exportDirectoryType = value;
            };

            $scope.exportFileFormatOptionClick = function (value) {
                $scope.interoperabilitySettings.exportFileFormatOption = value;
            };

            // Must append \ at the end to pass regular expression validity.
            $scope.getFixedDirectory = function (dir) {
                if (dir) {
                    var _tmpstr = dir.substr(dir.length - 1, 1);
                    dir += _tmpstr != "\\" ? "\\" : "";
                }
                return dir;
            };

            function autoCompleteAddresses(query) {
                query = query.toLowerCase();
                var addresses = [];

                // Get Addresses from storage cache
                var storageAddresses = AddressesCacheFactory.get();
                if (storageAddresses) {
                    addresses = storageAddresses;
                }

                // Get Addresses from Document Sources
                _.each($scope.availableFromAddresses, function (item) {
                    if (!_.includes(addresses, item)) {
                        addresses.push(item);
                    }
                });

                // Filter list with query value
                var filteredAddresses = [];
                addresses = addresses.sort();
                _.each(addresses, function (item) {
                    item = item.toLowerCase();
                    if (item.indexOf(query) > -1) {
                        filteredAddresses.push({ text: item });
                    }
                });

                var deferred = $q.defer();
                deferred.resolve(filteredAddresses);
                return deferred.promise;
            }

            $scope.saveChanges = function () {
                $scope.form.$valid = true;
                var mode = $scope.interoperabilitySettings.exportMode;
                var option = $scope.interoperabilitySettings.exportOption;
                var message = "";

                $scope.interoperabilitySettings.directAddress = _($scope.toAddresses).map("text").join(";");

                // Form validations
                if (mode == "emr") {
                    if (!$scope.interoperabilitySettings.forwardOption) {
                        message = "Must select a Forwarding Option.";
                    }
                    if (!$scope.interoperabilitySettings.directAddress) {
                        message = "Must provide a Direct Address.";
                    }

                    if (!$scope.interoperabilitySettings.fromDirectAddress) {
                        message = "Must provide a Direct Address.";
                    }
                }

                if (mode == "save_as") {
                    if (!$scope.interoperabilitySettings.exportOption) {
                        message = "Must select an export option.";
                    }
                }

                if (option == "standard") {
                    if (!$scope.interoperabilitySettings.exportDirectoryType) {
                        message = "Must select an export directory type.";
                    }
                    if (!$scope.interoperabilitySettings.exportFileFormatOption) {
                        message = "Must select an export file format.";
                    }
                }

                if (option) {
                    if (!$scope.interoperabilitySettings.exportDirectory) {
                        message = "Must provide a valid export directory.";
                    }
                }

                $scope.form.$error.general = new Array();

                if ($scope.form.$error.required) {
                    _.each($scope.form.$error.required, function (x) {
                        $scope.form.$valid = false;
                    });
                }

                // Directory validation
                if ($scope.form.$valid && $scope.interoperabilitySettings.exportDirectory != null) {
                    $scope.form.$valid = !$scope.form.exportDirectory.$error.validdirectory;
                }

                if (
                    $scope.interoperabilitySettings.enableNonPatientDirectory &&
                    (!$scope.interoperabilitySettings.exportNonPatientDirectory || $scope.form.exportNonPatientDirectory.$error.nonPatientValidDirectory)
                ) {
                    message = "Must provide a valid non-patient export directory.";
                }

                if (message) {
                    $scope.form.$valid = false;
                }

                // Save changes if from valid
                if ($scope.form.$valid) {
                    clearInteroperabilitySettingsForOtherModes($scope.interoperabilitySettings.exportMode);

                    // Reset VisitDirectory ?
                    if ($scope.interoperabilitySettings.exportDirectoryType == "single_directory") {
                        $scope.interoperabilitySettings.exportVisitDirectory = false;
                    }

                    $scope.interoperabilitySettings.exportDirectory = $scope.getFixedDirectory($scope.interoperabilitySettings.exportDirectory);
                    $scope.interoperabilitySettings.exportNonPatientDirectory = $scope.getFixedDirectory(
                        $scope.interoperabilitySettings.exportNonPatientDirectory
                    );

                    InteroperabilityService.put($scope.interoperabilitySettings).then(
                        function (response) {
                            NotificationService.success("The download configuration was saved.");
                        },
                        function (response) {
                            NotificationService.error("There was an error saving the download configuration.");
                        }
                    );
                } else {
                    if (message) {
                        NotificationService.error(message);
                    } else {
                        NotificationService.error("The form is not valid.");
                    }
                }
            };

            function searchDirectory() {
                $uibModal
                    .open({
                        windowClass: "directory-search-modal",
                        templateUrl: directorySearchModalTemplate,
                        component: "k2DirectorySearchModal",
                        scope: $scope
                    })
                    .result.then(
                        function (addresses) {
                            var oldAddresses = _.map($scope.toAddresses, "text");
                            var allAddresses = _.uniq(addresses.concat(oldAddresses));
                            $scope.toAddresses = _.map(allAddresses, function (a) {
                                return { text: a };
                            });
                        },
                        () => {}
                    );
            }

            // Init
            (function () {
                $scope.isRestricted = $rootScope.hasUIResourceAction("forwardEmrPlanUpgrade", "planUpgradePrompt");
                if ($scope.isRestricted) {
                    $scope.cursor = "cursor:not-allowed";
                } else {
                    $scope.cursor = "";
                }
                $scope.interoperabilitySettings = {
                    exportMode: null, // print or save_as or emr
                    exportOption: null, // xds-sd_cda or standard
                    exportDirectoryType: null, // patient_folder or single_directory
                    exportFileFormatOption: null, // keep_original or convert2pdf
                    exportVisitDirectory: false,
                    exportDirectory: null,
                    exportNonPatientDirectory: null,
                    enableNonPatientDirectory: false,
                    directAddress: null, //for EMR forwarding
                    fromDirectAddress: null, //for EMR forwarding
                    forwardOption: null
                };
                $scope.$watch("interoperabilitySettings.enableNonPatientDirectory", function (val) {
                    if (val === false && $scope.form && $scope.form.exportNonPatientDirectory) {
                        $scope.form.exportNonPatientDirectory.$setValidity("nonPatientValidDirectory", true);
                    }
                });
                InteroperabilityService.get().then(
                    function (response) {
                        $scope.interoperabilitySettings = response.data;
                        if (response.data.directAddress)
                            $scope.toAddresses = _.map(response.data.directAddress.split(";"), function (x) {
                                return { text: x };
                            });
                    },
                    function (response) {
                        NotificationService.error("There was an error reading the download configuration.");
                    }
                );
                DocumentSourceService.getMyDocumentSources().then(function (data) {
                    $scope.availableFromAddresses = _.map(data, "address");
                });
            })();
        }
    ])

    .directive("validateDirectory", [
        "ValidateFactory",
        function (ValidateFactory) {
            return {
                require: "ngModel",
                link: function (scope, element, attrs, ctrl) {
                    element.on("keyup", function (evt) {
                        scope.$apply(function () {
                            var message = stringFormat("You must provide a valid directory.");
                            var val = scope.getFixedDirectory(element.val());

                            // RegExp source reference: http://regexlib.com/REDetails.aspx?regexp_id=1785
                            var pattern =
                                /^([A-Za-z]:|\\{2}([-\w]+|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))\\(([^"*/:?|<>\\,;[\]+=.\x00-\x20]|\.[.\x20]*[^"*/:?|<>\\,;[\]+=.\x00-\x20])([^"*/:?|<>\\,;[\]+=\x00-\x1F]*[^"*/:?|<>\\,;[\]+=\x00-\x20])?))\\([^"*/:?|<>\\.\x00-\x20]([^"*/:?|<>\\\x00-\x1F]*[^"*/:?|<>\\.\x00-\x20])?\\)*$/;
                            var regEx = new RegExp(pattern);
                            var valid = regEx.test(val);

                            var validationErrorKey = attrs.validateDirectory || "validdirectory";

                            ValidateFactory.setValidity(element, attrs, ctrl, validationErrorKey, valid, message);
                        });
                    });
                }
            };
        }
    ]);
