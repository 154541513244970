import { Component, inject } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { FooterComponent } from "../footer/footer.component";
import { HeaderComponent } from "../header/header.component";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { SidebarService } from "../sidebar/sidebar.service";

@Component({
    selector: "kno2-home",
    standalone: true,
    imports: [HeaderComponent, SidebarComponent, FooterComponent, RouterOutlet],
    templateUrl: "./home.component.html"
})
export class HomeComponent {
    private readonly sideBarService = inject(SidebarService);
    private readonly upgrade = inject(UpgradeModule);
    protected readonly sidebarCollapsed = this.sideBarService.collapsed;
}
