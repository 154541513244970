import { Component, inject, input, output } from "@angular/core";
import { UsersService } from "@kno2/data-access/users";
import { ConfirmationDialogService } from "@kno2/shared/ui/confirmation-dialog";
import { noop } from "angular";

@Component({
    selector: "kno2-profile-authorized-applications",
    standalone: true,
    imports: [],
    templateUrl: "./profile-authorized-applications.component.html"
})
export class ProfileAuthorizedApplicationsComponent {
    private readonly confirmationDialogService = inject(ConfirmationDialogService);
    private readonly usersService = inject(UsersService);

    public userId = input.required<string>();
    public authorizedApps = input([]);
    public onRemoveApp = output<void>();

    public async removeApplication(appId: string): Promise<void> {
        const result = await this.confirmationDialogService
            .open({
                bodyText: "Are you sure you want to remove this application?",
                okText: "Remove"
            })
            .result.catch(noop);

        if (!result) return;

        try {
            await this.usersService.removeApplication(this.userId(), appId);
            this.onRemoveApp.emit();
        } catch (err) {
            noop();
        }
    }
}
