import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FileDropZoneComponent } from "@kno2/shared/ui";

@Component({
    selector: "kno2-contacts-upload-card",
    imports: [FileDropZoneComponent],
    templateUrl: "./contacts-upload-card.component.html",
    standalone: true
})
export class ContactsUploadCardComponent {
    @Input() public isUploading: boolean;
    @Output() public onDownloadClick = new EventEmitter<void>();
    @Output() public onUploadFile = new EventEmitter<Array<File>>();

    public downloadContacts(): void {
        this.onDownloadClick.emit();
    }

    public uploadFile(files: Array<File>): void {
        this.onUploadFile.emit(files);
    }
}
