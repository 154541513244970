import subscriptionTierRestrictedModalTemplate from "../../settings/subscription/settings.subscription.tier-restricted-modal.html";

(function () {
    "use strict";

    var controller = function ($location, $uibModalInstance, SupportService, NotificationService, data, planUpgradePrompts) {
        this.resource = data.resource;
        this.isAdmin = data.isAdmin;
        this.messageKey = this.resource + (this.isAdmin ? "Admin" : "User");
        this.planUpgradePrompts = planUpgradePrompts;

        this.deferRequest = function () {
            $uibModalInstance.close();
        };

        this.doRequest = function () {
            if (this.isAdmin) {
                $location.path("/settings/billing");
            } else {
                SupportService.sendPlanUpgradeRequest().then(function () {
                    NotificationService.success("Your plan upgrade request has been sent.");
                });
            }
            $uibModalInstance.close();
        };
    };
    controller.$inject = ["$location", "$uibModalInstance", "SupportService", "NotificationService", "data", "planUpgradePrompts"];
    angular.module("kno2.controllers").controller("TierRestrictedModalCtrl", controller);

    var factory = function ($uibModal, SessionService) {
        return function (resource) {
            return $uibModal.open({
                templateUrl: subscriptionTierRestrictedModalTemplate,
                controller: "TierRestrictedModalCtrl as main",
                resolve: {
                    data: function () {
                        return {
                            isAdmin: SessionService.userInRole("Administrator"),
                            resource: resource
                        };
                    }
                }
            }).result;
        };
    };

    factory.$inject = ["$uibModal", "SessionService"];
    angular.module("kno2.factories").factory("PromptUpgradeModal", factory);
})();
