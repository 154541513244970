@for (formRow of formFields; track formRow) {
    <div class="row">
        @for (field of formRow; track field) {
            <div class="form-group col-sm">
                @switch (field.controlType) {
                    @case ("input") {
                        <kno2-form-input
                            [ngClass]="field.classNames?.join(' ')"
                            [control]="formGroup().controls[field.key]"
                            [hasError]="
                                (hasSubmitted || formGroup().controls[field.key].touched) &&
                                (formGroup().controls[field.key].invalid || formGroup().errors?.[field.key])
                            "
                            [placeholder]="field.placeholder || (field.key | camelCaseToTitleCase)"
                            [mask]="field.mask" />
                    }
                    @case ("select") {
                        <kno2-form-select
                            [ngClass]="field.classNames?.join(' ')"
                            [control]="formGroup().controls[field.key]"
                            [hasError]="
                                (hasSubmitted || formGroup().controls[field.key].touched) &&
                                (formGroup().controls[field.key].invalid || formGroup().errors?.[field.key])
                            "
                            [placeholder]="field.placeholder || (field.key | camelCaseToTitleCase)"
                            [options]="field.options" />
                    }
                    @case ("date") {
                        <kno2-form-date-input
                            [ngClass]="field.classNames?.join(' ')"
                            [control]="formGroup().controls[field.key]"
                            [hasError]="
                                (hasSubmitted || formGroup().controls[field.key].touched) &&
                                (formGroup().controls[field.key].invalid || formGroup().errors?.[field.key])
                            "
                            [placeholder]="field.placeholder || (field.key | camelCaseToTitleCase)"
                            [mask]="field.mask" />
                    }
                }
            </div>
        }
    </div>
}
