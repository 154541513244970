import { Component, inject, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UpgradeModule } from "@angular/upgrade/static";
import { IntegrationsService } from "@kno2/data-access/integrations";
import { User, UsersService } from "@kno2/data-access/users";
import { FeatureToggleDirective } from "@kno2/shared/directives";
import { LoadingSpinnerComponent, NotificationAlertComponent, NotificationService } from "@kno2/shared/ui";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProfileAuthorizedApplicationsComponent } from "./profile-authorized-applications.component";
import { ProfileEditComponent } from "./profile-edit.component";
import { ProfileEmailNotificationsComponent } from "./profile-email-notifications.component";
import { ProfileIntegrationsComponent } from "./profile-integrations.component";
import { ProfileUserPreferencesComponent } from "./profile-user-preferences.component";

@Component({
    selector: "kno2-profile-modal",
    standalone: true,
    imports: [
        NotificationAlertComponent,
        ProfileEditComponent,
        ProfileEmailNotificationsComponent,
        ProfileUserPreferencesComponent,
        ProfileAuthorizedApplicationsComponent,
        ProfileIntegrationsComponent,
        LoadingSpinnerComponent,
        FeatureToggleDirective
    ],
    templateUrl: "./profile-modal.component.html"
})
export class ProfileModalComponent {
    private readonly activeModal = inject(NgbActiveModal);
    private readonly integrationsService = inject(IntegrationsService);
    private readonly notificationService = inject(NotificationService);
    private readonly usersService = inject(UsersService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly sessionService = this.upgrade.$injector.get("SessionService");
    private readonly featureService = this.upgrade.$injector.get("FeatureService");

    protected form: FormGroup;
    protected user: User;
    protected hasCacheIntegrationData = false;
    protected error: string;
    protected mfaRequired: boolean;
    protected isSubmitting = false;

    @Input() public currentTab = "editProfile";
    @Input() public changingPassword = false;

    public async ngOnInit(): Promise<void> {
        this.setCacheConfigurationData();
        await this.loadUser();

        const { userPreferences } = this.user;
        this.mfaRequired = this.featureService.isEnabled("MFARequired");

        this.form = new FormGroup(
            {
                firstName: new FormControl(this.user.firstName, [Validators.required, Validators.maxLength(160)]),
                lastName: new FormControl(this.user.lastName, [Validators.required, Validators.maxLength(160)]),
                phoneNumber: new FormControl(this.user.phoneNumber, [Validators.required, Validators.maxLength(20)]),
                email: new FormControl(this.user.email, [Validators.required, Validators.email]),
                twoFactorEnabled: new FormControl({
                    value: this.mfaRequired || !this.user.canChangeTwoFactorSetting || this.user.twoFactorEnabled,
                    disabled: this.mfaRequired || !this.user.canChangeTwoFactorSetting
                }),
                userPreferences: new FormGroup({
                    afterProcessMessage: new FormControl(userPreferences.afterProcessMessage),
                    emailSystemNotifications: new FormControl({
                        value: userPreferences.emailSystemNotifications,
                        disabled: userPreferences.emailAllNotifications && this.user.isAdministrator
                    }),
                    emailNewMessages: new FormControl(userPreferences.emailNewMessages),
                    emailUnassignedMessages: new FormControl(userPreferences.emailUnassignedMessages),
                    emailUnprocessedMessages: new FormControl(userPreferences.emailUnprocessedMessages),
                    emailFailedMessagesDigest: new FormControl(userPreferences.emailFailedMessagesDigest)
                })
            },
            {
                updateOn: "change"
            }
        );
    }

    public async loadUser(): Promise<void> {
        const { userId } = this.sessionService.getProfile();
        this.user = await this.usersService.getUser(userId);
    }

    public async setCacheConfigurationData(): Promise<void> {
        const { capabilities } = await this.integrationsService.getCapabilities();
        this.hasCacheIntegrationData = !!capabilities.find((c) => c.display === "cacheIntegrationData");
    }

    public cancel(): void {
        this.activeModal.dismiss();
    }

    public async save(): Promise<void> {
        try {
            this.isSubmitting = true;

            if (this.form.value.phoneNumber !== this.user.phoneNumber) {
                this.user.phoneNumberConfirmed = false;
                this.user.twoFactorEnabled = this.mfaRequired || !this.user.canChangeTwoFactorSetting;
            }

            await this.usersService.updateUser({
                ...this.user,
                ...this.form.value
            });
            this.notificationService.success("Profile has been updated.");
            this.activeModal.close(this.form.value);
        } catch (err) {
            this.error = "There was an error updating your profile.";
        } finally {
            this.isSubmitting = false;
        }
    }
}
