import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SupportService {
    private httpClient = inject(HttpClient);

    public submitTicket = (ticket): Promise<void> => firstValueFrom(this.submitTicket$(ticket));
    public sendPlanUpgradeRequest = (): Promise<void> => firstValueFrom(this.sendPlanUpgradeRequest$());

    private submitTicket$(ticket): Observable<void> {
        return this.httpClient.post<void>("/api/support/ticket/", ticket);
    }

    private sendPlanUpgradeRequest$(): Observable<void> {
        return this.httpClient.post<void>("/api/support/plan/upgrade/request", null);
    }
}
