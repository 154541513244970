import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { AuthService } from "../services";

export const accessTokenResolver: ResolveFn<Promise<string>> = async (route) => {
    const authService = inject(AuthService);

    if (!authService.accessToken) {
        await authService.renewTokens();
    }

    return authService.accessToken;
};
