angular.module("kno2.services").factory("SessionService", [
    "$http",
    "$q",
    "_",
    "localStorageService",
    "Idle",
    "appInsightsService",
    function ($http, $q, _, localStorageService, Idle, appInsightsService) {
        var profileData = {};
        var key = "ProfileData";

        var getProfile = function () {
            if (_.isEmpty(profileData)) {
                var str = localStorageService.get(key);
                var data = str ? str : {};
                setProfile(data);
            }
            return profileData;
        };

        var getProfileAsync = function () {
            return $http.get("/api/users/profiledata");
        };

        var refreshProfile = function () {
            var promise = $http.get("/api/users/profiledata");
            return promise.then(
                function (response) {
                    setProfile(response.data);
                    return getProfile();
                },
                function (errorResponse) {
                    return $q.reject(errorResponse);
                }
            );
        };

        var getClientIp = function () {
            return $http.get("/api/users/ipaddress");
        };

        var updateProfile = function (data) {
            angular.extend(profileData, data);
            localStorageService.set(key, data);

            appInsightsService.updateProfile(data.userId, data.organizationId);
        };

        var setProfile = function (data) {
            if (data && data.sessionTimeoutMinutes) {
                const timeout = data.sessionTimeoutMinutes || 10;
                let idleDuration = timeout * 60 - 60;
                if (idleDuration <= 1) idleDuration = 5;
                Idle.setIdle(idleDuration);
            }

            updateProfile(data);
        };

        var userInRole = function (roles) {
            if (_.isString(roles))
                roles = roles.split(",").reduce(function (result, str) {
                    var value = str.trim();
                    if (value) result.push(value);
                    return result;
                }, []);
            roles = [].concat(roles);
            var matched = _.intersection(getProfile().roles, roles);
            return matched.length > 0;
        };

        var isSysAdmin = function () {
            return userInRole(["SysAdmin", "SysAdminReporting"]);
        };

        var hasUIResourceAction = function (resource, action) {
            var profile = getProfile();
            if (_.has(profile.uiResources || {}, resource)) {
                return _.includes(profile.uiResources[resource], action);
            }
            return false;
        };

        var getUIResourceActions = function (resource) {
            var profile = getProfile();
            return profile.uiResources[resource];
        };

        var removeProfile = function () {
            localStorageService.remove(key);
        };

        return {
            getProfile: getProfile,
            getProfileAsync: getProfileAsync,
            setProfile: setProfile,
            refreshProfile: refreshProfile,
            getClientIp: getClientIp,
            userInRole: userInRole,
            isSysAdmin: isSysAdmin,
            hasUIResourceAction: hasUIResourceAction,
            getUIResourceActions: getUIResourceActions,
            removeProfile: removeProfile
        };
    }
]);
