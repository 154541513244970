import { find, includes, get, forEach, pickBy } from "lodash";

let ctrl;
export class IntegrationsUpsertModalController {
    public static $inject: Array<string> = [
        "IntegrationsService",
        "NotificationModalService",
        "NotificationService",
        "CodeGrantsService",
        "DocumentSourcesService",
        "FeatureService"
    ];

    public resolve;
    public modalInstance;

    public availableTypes: Array<any>;
    public types: Array<any>;
    public integration: any;
    public readonlyProperties: any;
    public selectedIntegration: any;
    public savingIntegration: boolean;
    public codeGrants: Array<any>;
    public facilities: Array<any>;
    public checkingConnection: boolean;
    public connected: boolean;
    public setProperties: boolean;

    constructor(
        private IntegrationsService,
        private NotificationModalService,
        private NotificationService,
        private CodeGrantsService,
        private DocumentSourcesService,
        private FeatureService
    ) {}

    public $onInit(): void {
        ctrl = this;
        this.availableTypes = this.resolve.availableTypes;
        this.types = this.resolve.types;
        this.integration = this.resolve.integration || {};
        this.readonlyProperties = [];

        if (this.integration && this.integration.capabilities && this.integration.capabilities.facilityLookup) {
            this.getFacilities();
        }

        this.integrationSelected();
    }

    public integrationSelected(): void {
        this.selectedIntegration = find(this.types, (x) => x.type === this.integration.type);

        console.log(this.selectedIntegration.name);

        if (this.integration.id) {
            this.selectedIntegration.properties.forEach((p) => {
                const hasValue = this.integration.values[p.name] !== undefined && this.integration.values[p.name] !== null;

                if (hasValue) {
                    this.readonlyProperties.push(p);
                    this.integration.values[p.name + "Readonly"] = this.integration.values[p.name];

                    if (p.isTagsInput) {
                        this.integration.values[p.name + "Readonly"] = this.integration.values[p.name].replace(";", ", ");
                        if (p.dataSource === null) {
                            this.integration.values[p.name] = this.integration.values[p.name].split(";").map((x) => {
                                return { text: x };
                            });
                        } else {
                            this.integration.values[p.name] = this.integration.values[p.name].split(";");
                        }
                    }
                }
            });
        } else if (this.selectedIntegration) {
            this.integration.capabilities = this.selectedIntegration.capabilities.reduce((result, x) => {
                const defaultEnabledCapabilities = get(ctrl, "selectedIntegration.defaultEnabledCapabilities", []);
                if (defaultEnabledCapabilities.length) {
                    result[x] = includes(defaultEnabledCapabilities, x);
                } else {
                    result[x] = true;
                }
                return result;
            }, {});
        }

        if (this.selectedIntegration) {
            this.integration.values = this.integration.values || {};
            this.selectedIntegration.properties.forEach((p) => {
                if (this.integration.values[p.name] === undefined && p.defaultValue !== null) this.integration.values[p.name] = p.defaultValue;
                if (p.dataSource && p.dataSource.name) this.getDataSource(p);
            });
            if (this.integration.capabilities["haveCodeGrantLogin"]) this.getCodeGrants(this.integration.type);
        }
    }

    public saveChanges(): any {
        this.savingIntegration = true;
        let integrationCopy = JSON.parse(JSON.stringify(this.integration));

        let props = this.selectedIntegration.properties.filter((x) => x.isTagsInput);
        if (props.length) {
            props.forEach((p) => {
                if (this.integration.values[p.name]) {
                    if (p.dataSource === null) {
                        integrationCopy.values[p.name] = this.integration.values[p.name].map((x) => x.text).join(";");
                    } else {
                        integrationCopy.values[p.name] = this.integration.values[p.name].join(";");
                    }
                }
            });
        }

        return this.IntegrationsService.upsert(integrationCopy)
            .then(
                (res) => {
                    this.modalInstance.close();
                    if (res.message) this.NotificationService.notice(res.message);
                },
                (err) => {
                    if (err.data.message) this.NotificationModalService.error(err.data.message);
                    else this.NotificationModalService.serverError(err.data.modelState, "There was an error saving the integration.");
                }
            )
            .then(() => {
                this.savingIntegration = false;
            });
    }

    public dismissModal(): void {
        this.modalInstance.dismiss();
    }

    public getCodeGrants(integrationType): any {
        return this.CodeGrantsService.getCodeGrants(integrationType)
            .then((res) => (this.codeGrants = res))
            .catch((err) => console.log(err));
    }

    public displayCheckConnection(): boolean {
        return this.selectedIntegration && this.selectedIntegration.allowCheckForConnectivity;
    }

    public checkConnection(): void {
        const self = this;
        self.checkingConnection = true;
        self.connected = null;

        const testById = self.integration.id && !self.setProperties;
        const payload = testById ? self.integration.id : self.integration;
        const method = testById ? "testConnectionById" : "testConnection";
        self.IntegrationsService[method](payload)
            .then((connected) => (self.connected = connected.isSuccess))
            .catch((err) => {
                self.connected = false;
                self.NotificationService.serverError(err.data.modelState, err.data.exceptionMessage);
            })
            .finally(function () {
                self.checkingConnection = false;
            });
    }

    public getFacilities(): void {
        this.IntegrationsService.getFacilities()
            .then((data) => {
                this.facilities = forEach(data, (x) => {
                    x.id = x.id.toString();
                });
            })
            .catch((err) => {
                this.NotificationModalService.serverError(err.data.modelState, "There was an error retrieving facilities.");
            });
    }

    public getDataSource(property): any {
        switch (property.dataSource.name) {
            case "DocumentSource":
                if (property.documentSourceType === "All") {
                    this.DocumentSourcesService.getAllDocumentSources().then((data) => {
                        property.values = data.documentSources.map((x) => {
                            return { name: x.address, value: x.address };
                        });
                    });
                } else {
                    this.DocumentSourcesService.getDocumentSources("asc", 1, 100).then((data) => {
                        property.values = data.documentSources.map((x) => {
                            return { name: x.address, value: x.address };
                        });
                    });
                }
                break;

            case "Integration":
                this.IntegrationsService.list().then((data) => {
                    property.values = data.map((x) => {
                        return { name: x.name, value: x.type };
                    });
                });
                break;

            case "DocusignEnvelopeCustomField":
                this.IntegrationsService.getPatientKeys().then((data) => {
                    property.values = data;
                });
                break;

            case "DateRangeForDischargedPatients":
                property.values = [
                    {
                        name: "Last 6 Months",
                        value: 6
                    },
                    {
                        name: "Last 12 Months",
                        value: 12
                    },
                    {
                        name: "Last 18 Months",
                        value: 18
                    },
                    {
                        name: "Last 24 Months",
                        value: 24
                    },
                    {
                        name: "Last 30 Months",
                        value: 30
                    },
                    {
                        name: "Last 36 Months",
                        value: 36
                    }
                ];
                break;

            case "SourceType":
                property.values = [
                    {
                        name: "DirectMessage",
                        value: "DirectMessage"
                    },
                    {
                        name: "RecordRequest",
                        value: "RecordRequest"
                    },
                    {
                        name: "Triage",
                        value: "Triage"
                    },
                    {
                        name: "Fax",
                        value: "Fax"
                    },
                    {
                        name: "Carequality",
                        value: "Carequality"
                    },
                    {
                        name: "NaviHealth",
                        value: "NaviHealth"
                    },
                    {
                        name: "Ihde",
                        value: "Ihde"
                    }
                ];
                break;
        }
    }

    public getInputType(property): string {
        if (property.dataSource && property.dataSource.type === "StringList") {
            return "StringList";
        }

        if (property.values) {
            return null;
        }

        switch (property.type) {
            case "Boolean":
                return "checkbox";
            case "String":
                if (property.isSensitive) return "password";
                else return "text";
            default:
                return "text";
        }
    }

    public toggleCapability(c): void {
        if (this.selectedIntegration.allowCapabilitiesToBeEdited && c !== "haveCodeGrantLogin")
            this.integration.capabilities[c] = !this.integration.capabilities[c];
    }

    public toggleCodeGrantCapability(p): void {
        if (this.integration.values[p.name] === "OAuth2AuthorizationCode") this.integration.capabilities["haveCodeGrantLogin"] = true;
        else delete this.integration.capabilities["haveCodeGrantLogin"];
    }

    public displayPropertyFormElement(p): any {
        return (
            !p.isList &&
            (!p.displayIfHasFeature || this.FeatureService.isEnabled(p.displayIfHasFeature)) &&
            (!p.displayIfHasValue || this.integration.values[p.name]) &&
            (!p.displayWhen || p.displayWhen.value.indexOf(this.integration.values[p.displayWhen.property]) > -1) &&
            !p.hideFromUi
        );
    }

    public isAdvancedOption(p): boolean {
        return p.advancedOption || p.option || false;
    }

    public hasAdvancedOptions(): boolean {
        if (this.selectedIntegration) {
            const property = find(this.selectedIntegration.properties, { advancedOption: {} });
            return property != null;
        }
        return false;
    }

    public getAdvancedOptionName(): string {
        const property = find(this.selectedIntegration.properties, { advancedOption: {} });
        if (property) {
            return property.name;
        }

        return "";
    }

    public getAdvancedOption(): any {
        var option = find(this.selectedIntegration.properts, { advancedOption: {} });
        return option;
    }

    public isRequiredInput(value): boolean {
        if (value.requiredWhenBooleanProperty) {
            return this.integration.values[value.requiredWhenBooleanProperty];
        }
        return true;
    }

    public getEnabledIntegrations(p, v): any {
        const integrations = this.integration.values[p.name][v.name];
        const filtered = pickBy(integrations, function (value, key) {
            return value.isDocuSignConnected;
        });

        return filtered;
    }

    public isESignatureForm(integrationName): boolean {
        return this.isDocusignApiForm(integrationName) || this.isDocusignConnectForm(integrationName) || this.isJSignForm(integrationName);
    }

    public isDocusignApiForm(integrationName): boolean {
        return integrationName === "DocuSign Api";
    }

    public isDocusignConnectForm(integrationName): boolean {
        return integrationName === "DocuSign Connect";
    }

    public isJSignForm(integrationName): boolean {
        return integrationName === "jSign";
    }
}

export class IntegrationsUpsertModalComponent {
    public templateUrl: any;
    public controller: any;
    public bindings: Object;

    constructor() {
        this.templateUrl = require("./integrations-upsert.modal.component.html");
        this.controller = IntegrationsUpsertModalController;
        this.bindings = {
            resolve: "<",
            modalInstance: "<"
        };
    }
}
