import { LocalStorageService } from "@kno2/shared/services";

export class UserPreferencesService {
    public static $inject: Array<string> = ["UsersService", "localStorageService"];
    public static readonly USER_PREFERENCES_KEY = "userPreferences";

    constructor(
        private usersService,
        private localStorageService: LocalStorageService
    ) {}

    public async load(): Promise<any> {
        const user = await this.usersService.getCurrent();
        const preferences = user.userPreferences;

        this.setUserPreferences(preferences);
        return user;
    }

    public getUserPreferences(): any {
        return this.localStorageService.get(UserPreferencesService.USER_PREFERENCES_KEY);
    }

    public setUserPreferences(preferences: any): void {
        this.localStorageService.set(UserPreferencesService.USER_PREFERENCES_KEY, preferences);
    }
}
