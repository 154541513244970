import angularFilter from "angular-filter";

import { CoreModule } from "../config";
import { cdaPreviewFrameComponent } from "./components/cda-preview-frame.component";
import { dateRangePickerComponent } from "./components/date-range-picker/date-range-picker.component";
import { inputFilterComponent } from "./components/input-filter/input-filter.component";
import { jumpPagerComponent } from "./components/jump-pager/jump-pager.component";
import { notificationListComponent } from "./components/notification-list/notification-list.component";
import { patientAttachmentsComponent } from "./components/patient-attachments/patient-attachments.component";
import { pdfViewerFrameComponent } from "./components/pdf-viewer-frame.component";
import { viewerPanelComponent } from "./components/viewer-panel/viewer-panel.component";
import { viewerComponent } from "./components/viewer.component";
import { UserOrganizationCtrl } from "./controllers/user-organization.ctrl";
import { DeleteMessageModalCtrl } from "./delete-message/delete-message-modal.ctrl";
import {
    datePickerAutoFormatDirective,
    datePickerConfig,
    datePickerInputDirective,
    datepickerPopupReadonlyDirective,
    openOnFocusDirective,
    uibDatepickerPopupDirective
} from "./directives/datepicker.directive";
import { formatContactDisplayFilter } from "./filters/contact.filter";
import { documentDateFormatFilter } from "./filters/document-date-format.filter";
import { downloadProfileFilenameFilter } from "./filters/download-profile-filename.filter";
import { friendlyKeyName } from "./filters/friendly-key-name.filter";
import { identityProviderValueFilter } from "./filters/identityProviderValue.filter";
import { statusAbbrevFilter } from "./filters/status-abbrev.filter";
import { titlecaseFilter } from "./filters/titlecase.filter";
import { toSentenceFilter } from "./filters/to-sentence.filter";
import { messageRowRepeater } from "./message-row-repeater/message-row-repeater.component";
import { BrandService } from "./services/brand.service";
import { ConfigurationService } from "./services/configuration.service";
import { PrintService } from "./services/print.service";
import { QueryService } from "./services/query.service";
import { UnsupportedBrowserService } from "./services/unsupported-browser.service";
import { SessionTimeoutCtrl } from "./session-timeout/session-timeout.ctrl";
import { SessionTimeoutModalCtrl } from "./session-timeout/session-timeout.modal.ctrl";
import { UserInvitationModalCtrl } from "./user-invitation/account.dashboard.user-invitation-modal.ctrl";

import { UserPreferencesService } from "../account/profile/user-preferences/user-preferences.service.ts";
import { homeLayoutComponent } from "../layouts/home.layout.component.ts";
import { paginationComponent } from "./components/pagination/pagination.component";
import { brandSettingsToggleDirective } from "./directives/brand-settings.directive";
import { datepickerLocalDateDirective } from "./directives/datepickerlocal.directive";
import { QueryMetricsFactory } from "./factories/querymetrics.factory";
import { BrandSettingsService } from "./services/brand-settings.service.ts";
import { ContactsService } from "./services/contacts.service.ts";
import { SingleSignOnService } from "./services/single-sign-on.service.ts";
import { StateRoutingService } from "./services/state-routing.service";

import { CommonData } from "./factories/common-data.factory.ts";
import { AdminExternalServiceAccountsService } from "./services/admin.external-service-accounts.service";
import { SettingsService } from "./services/settings.service";

angular
    .module(CoreModule)
    .service("BrandService", BrandService)
    .service("ConfigurationService", ConfigurationService)
    .factory("QueryService", QueryService)
    .factory("QueryMetricsFactory", [QueryMetricsFactory])
    .controller("DeleteMessageModalCtrl", DeleteMessageModalCtrl)
    .controller("SessionTimeoutCtrl", SessionTimeoutCtrl)
    .controller("SessionTimeoutModalCtrl", SessionTimeoutModalCtrl)
    .controller("UserOrganizationCtrl", UserOrganizationCtrl)
    .controller("UserInvitationModalCtrl", UserInvitationModalCtrl)
    .component("homeLayout", homeLayoutComponent)
    .component("k2PatientAttachments", patientAttachmentsComponent)
    .component("messageRowRepeater", messageRowRepeater)
    .filter("toSentence", toSentenceFilter)
    .filter("documentDateFormat", documentDateFormatFilter)
    .filter("statusAbbrev", statusAbbrevFilter);

angular.module("kno2.controllers", []); // controllers shared across modules
angular
    .module("kno2.services", ["angularSpinner", "angular-data.DSCacheFactory", "kno2.libs", "ngSanitize"])
    .service("UnsupportedBrowserService", UnsupportedBrowserService)
    .service("StateRoutingService", StateRoutingService)
    .service("SingleSignOnService", SingleSignOnService)
    .service("SettingsService", SettingsService)
    .service("AdminExternalServiceAccountsService", AdminExternalServiceAccountsService)
    .service("ContactsService", ContactsService);

angular
    .module("kno2.directives", ["ui.bootstrap", "ng-iscroll", "ngTagsInput", "ui.mask", "ngclipboard", "kno2.libs", angularFilter])
    .run(datePickerConfig)
    .directive("uibDatepickerPopup", uibDatepickerPopupDirective)
    .directive("openOnFocus", openOnFocusDirective)
    .directive("datepickerPopupReadonly", datepickerPopupReadonlyDirective)
    .directive("datePickerInput", datePickerInputDirective)
    .directive("datePickerAutoFormat", datePickerAutoFormatDirective)
    .directive("datepickerLocalDate", datepickerLocalDateDirective)
    .directive("k2BrandSettingsToggle", brandSettingsToggleDirective);

angular
    .module("kno2.components", ["kno2.directives", "ngCookies"])
    .component("k2JumpPager", jumpPagerComponent)
    .component("k2Pagination", paginationComponent)
    .component("k2DatePicker", dateRangePickerComponent)
    .component("k2InputFilter", inputFilterComponent)
    .component("notificationList", notificationListComponent);

angular
    .module("kno2.filters", ["kno2.filters"])
    .filter("titlecase", titlecaseFilter)
    .filter("identityProviderValue", identityProviderValueFilter)
    .filter("downloadProfileFilename", downloadProfileFilenameFilter)
    .filter("friendlyKeyName", friendlyKeyName)
    .filter("formatContactDisplay", ["$filter", formatContactDisplayFilter]);

angular.module("kno2.factories", []).factory("CommonData", CommonData);

angular.module("kno2.shared.settings", ["localytics.directives"]);

angular
    .module("kno2.libs", [])
    .factory("moment", ["$window", ($window) => $window.moment])
    .factory("_", ["$window", ($window) => $window._]);

export const CommonModule = angular
    .module("kno2.client.common", [
        CoreModule,
        "kno2.controllers",
        "kno2.services",
        "kno2.directives",
        "kno2.components",
        "kno2.filters",
        "kno2.shared.settings",
        "kno2.libs"
    ])
    .service("PrintService", PrintService)
    .service("UserPreferencesService", UserPreferencesService)
    .service("BrandSettingsService", BrandSettingsService)
    .component("k2Viewer", viewerComponent)
    .component("k2ViewerPanel", viewerPanelComponent)
    .component("k2CdaPreviewFrame", cdaPreviewFrameComponent)
    .component("k2PdfViewerFrame", pdfViewerFrameComponent).name;
