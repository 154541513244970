import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AppConfigToken } from "@kno2/interop";
import { filterNonEmptyProperties } from "@kno2/shared/utils";
import { Observable, firstValueFrom } from "rxjs";

export interface Eula {
    id: number;
    version: string;
    active: boolean;
    url: string;
    type: "EULA" | "Privacy";
    brand: { name: string };
    totalUsers: number;
}

interface PageOptions {
    pageNumber?: number;
    pageSize?: number;
    search?: string;
    sortDir?: "asc" | "desc";
    sortBy?: string;
}

@Injectable({
    providedIn: "root"
})
export class EulasService {
    private httpClient = inject(HttpClient);
    private appConfig = inject(AppConfigToken);

    public getDefaultPageParameters(): PageOptions {
        return {
            pageNumber: 1,
            pageSize: 10,
            sortDir: "desc"
        };
    }

    public getPageSizeOptions() {
        return [10, 25, 50, 100];
    }

    public getPages = (params: PageOptions): Promise<{ items: Eula[]; total: number }> => firstValueFrom(this.getPages$(params));
    public getById = (id: string): Promise<Eula> => firstValueFrom(this.getById$(id));
    public save = (eula: Eula, file: File): Promise<Eula> => firstValueFrom(this.save$(eula, file));
    public delete = (id: number): Promise<void> => firstValueFrom(this.delete$(id));
    public activate = (id: number): Promise<void> => firstValueFrom(this.activate$(id));
    public isEulaVersionExists = (id: number, version: string, brandName: string): Promise<boolean> =>
        firstValueFrom(this.isEulaVersionExists$(id, version, brandName));
    public accept = (id: number): Promise<void> => firstValueFrom(this.accept$(id));
    public getActive = (type: Eula["type"]): Promise<Eula> => firstValueFrom(this.getActive$(type));
    public getViewActive = (type: Eula["type"]): Promise<string> => firstValueFrom(this.getViewActive$(type));
    public view = (id: number): Promise<string> => firstValueFrom(this.view$(id));

    private getPages$(params: PageOptions = {}): Observable<{ items: Eula[]; total: number }> {
        params = { ...this.getDefaultPageParameters(), ...params };
        return this.httpClient.get<{ items: Eula[]; total: number }>("/api/admin/eulas", {
            params: filterNonEmptyProperties(params)
        });
    }

    private getById$(id: string): Observable<Eula> {
        return this.httpClient.get<Eula>(`/api/admin/eulas/${id}`);
    }

    private save$(eula: Eula, file: File): Observable<Eula> {
        const formData = new FormData();
        formData.append("eula", JSON.stringify(eula));
        formData.append("file", file);
        return this.httpClient.post<Eula>("/api/admin/eulas", formData);
    }

    private delete$(id: number): Observable<void> {
        return this.httpClient.delete<void>(`/api/admin/eulas/${id}`);
    }

    private activate$(id: number): Observable<void> {
        return this.httpClient.post<void>(`/api/admin/eulas/${id}/activate`, null);
    }

    private isEulaVersionExists$(id: number, version: string, brandName: string): Observable<boolean> {
        return this.httpClient.get<boolean>(`/api/eulas/exists`, {
            params: { id, version, brandName }
        });
    }

    private accept$(id: number): Observable<void> {
        return this.httpClient.post<void>(`/api/eulas/${id}/accept`, null);
    }

    private getActive$(type: Eula["type"] = "EULA"): Observable<Eula> {
        return this.httpClient.get<Eula>(`/api/eulas/active`, {
            params: { type }
        });
    }

    private getViewActive$(type: Eula["type"] = "EULA"): Observable<string> {
        return this.httpClient.get(`/api/eulas/active/view`, {
            params: {
                type,
                brandName: this.appConfig.brandDisplayName
            },
            responseType: "text"
        });
    }

    private view$(id: number): Observable<string> {
        return this.httpClient.get(`/api/admin/eulas/${id}/view`, {
            responseType: "text"
        });
    }
}
