import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { User } from "./user.model";

@Injectable({
    providedIn: "root"
})
export class UsersService {
    private readonly httpClient = inject(HttpClient);

    public getUser = (userId: string): Promise<User> => firstValueFrom(this.getUser$(userId));
    public updateUser = (user: User): Promise<User> => firstValueFrom(this.updateUser$(user));
    public removeApplication = (userId: string, appId: string): Promise<void> => firstValueFrom(this.removeApplication$(userId, appId));

    private getUser$(userId: string): Observable<User> {
        return this.httpClient.get<User>(`/api/users/${userId}`);
    }

    private updateUser$(user: User): Observable<User> {
        return this.httpClient.put<User>(`/api/users/${user.id}`, user);
    }

    private removeApplication$(userId: string, appId: string): Observable<void> {
        return this.httpClient.delete<void>(`/api/users/${userId}/applications/${appId}`);
    }
}
