import { Validators } from "@angular/forms";
import { COUNTRIES, LANGUAGES, STATES } from "@kno2/shared/constants";
import { IntegrationPatientField } from "../integration-patient-dynamic-form/integration-patient-field";

export const GENDER_OPTIONS: IntegrationPatientField["options"] = [
    ["Male", "M"],
    ["Female", "F"],
    ["Undifferentiated", "UN"]
];

export const RACE_OPTIONS: IntegrationPatientField["options"] = [
    ["American Indian or Alaska Native", "American Indian or Alaska Native"],
    ["Asian", "Asian"],
    ["Black or African American", "Black or African American"],
    ["Native Hawaiian or Other Pacific Islander", "Native Hawaiian or Other Pacific Islander"],
    ["White", "White"],
    ["Other Race", "Other Race"],
    ["Asked but Unknown", "Asked but Unknown"],
    ["Unknown", "Unknown"]
];

export const ADDRESS_USE_OPTIONS: IntegrationPatientField["options"] = [
    ["Home", "Home"],
    ["Work", "Work"],
    ["Temporary", "Temporary"],
    ["Old or Incorrect", "Old / Incorrect"],
    ["Billing", "Billing"]
];

export const ADDRESS_TYPE_OPTIONS: IntegrationPatientField["options"] = [
    ["Postal", "Postal"],
    ["Physical", "Physical"],
    ["Postal & Physical", "Postal & Physical"]
];

export const PHONE_TYPE_OPTIONS: IntegrationPatientField["options"] = [
    ["Personal", "Personal"],
    ["Home", "Home"],
    ["Work/Office", "Work/Office"],
    ["Mobile", "Mobile"],
    ["Evening", "Evening"],
    ["Mother", "Mother"],
    ["Father", "Father"],
    ["Spouse", "Spouse"],
    ["Fax", "Fax"],
    ["Other", "Other"],
    ["Old - No Longer in Use", "Old"],
    ["Temporary", "Temporary"]
];

export const EMAIL_TYPE_OPTIONS: IntegrationPatientField["options"] = [
    ["Personal", "Personal"],
    ["Home", "Home"],
    ["Work/Office", "Work/Office"],
    ["Mobile", "Mobile"],
    ["Mother's Email", "Mother's Email"],
    ["Father's Email", "Father's Email"],
    ["Old - No Longer in Use", "Old"],
    ["Temporary", "Temporary"]
];
export const DEFAULT_ADD_PATIENT_FORM: IntegrationPatientField[][] = [
    [
        { key: "firstName", controlType: "input", validators: [Validators.minLength(2)] },
        { key: "middleName", controlType: "input" },
        { key: "lastName", controlType: "input", validators: [Validators.minLength(2)] }
    ],
    [
        {
            key: "birthdate",
            controlType: "date",
            transformer: (value: string) => {
                if (!value) return null;
                const date = new Date(value);
                return {
                    year: date.getUTCFullYear(),
                    month: date.getUTCMonth() + 1,
                    day: date.getUTCDate()
                };
            },
            mask: "00/00/0000"
        },
        { key: "gender", controlType: "select", options: GENDER_OPTIONS },
        { key: "socialSecurityNumber", controlType: "input", mask: "000-00-0000" }
    ],
    [{ key: "raceCategory", controlType: "select", options: RACE_OPTIONS }],
    [{ key: "language", controlType: "select", options: LANGUAGES }],
    [
        { key: "addressUse", controlType: "select", options: ADDRESS_USE_OPTIONS },
        { key: "addressType", controlType: "select", options: ADDRESS_TYPE_OPTIONS }
    ],
    [
        { key: "streetAddress1", controlType: "input" },
        { key: "streetAddress2", controlType: "input" }
    ],
    [
        { key: "city", controlType: "input" },
        { key: "state", controlType: "select", options: STATES },
        { key: "postalCode", controlType: "input", placeholder: "Zip Code", mask: "00000" }
    ],
    [{ key: "country", controlType: "select", options: COUNTRIES }],
    [
        { key: "phoneType", controlType: "select", options: PHONE_TYPE_OPTIONS },
        { key: "telephone", controlType: "input", mask: "(000) 000-0000" }
    ],
    [
        { key: "emailType", controlType: "select", options: EMAIL_TYPE_OPTIONS },
        { key: "email", controlType: "input", validators: [Validators.email] }
    ],
    [{ key: "medicalRecordNumber", controlType: "input", validators: [Validators.minLength(2)] }]
];

export const APRIMA_RACE_OPTIONS: IntegrationPatientField["options"] = [
    ["American Indian or Alaska Native", "American Indian or Alaska Native"],
    ["Asian", "Asian"],
    ["Black or African American", "Black or African American"],
    ["Native Hawaiian or Other Pacific Islander", "Native Hawaiian or Other Pacific Islander"],
    ["White", "White"],
    ["Declined to specify", "Declined to specify"],
    ["Not Provided", "Not Provided"]
];

export const APRIMA_PHONE_TYPE_OPTIONS: IntegrationPatientField["options"] = [
    ["Home", "home"],
    ["Cell", "Cell"],
    ["Office", "Office"],
    ["Work", "work"],
    ["Evening", "night"],
    ["Fax", "fax"],
    ["Father's Cell", "Dad's cell"],
    ["Mother's Cell", "Mom's cell"],
    ["Spouse's Cell", "Spouse's cell"],
    ["Text", "Text Cell"],
    ["Pager", "pager"],
    ["Rx", "rx"],
    ["Claims", "claims"],
    ["Other", "other"]
];

export const FHIR_GENDER_OPTIONS: IntegrationPatientField["options"] = [
    ["Male", "M"],
    ["Female", "F"],
    ["Other", "UN"],
    ["Unknown", "UNK"]
];

export const FHIR_RACE_CATEGORY_OPTIONS: IntegrationPatientField["options"] = [
    ["American Indian or Alaska Native", "American Indian or Alaska Native"],
    ["Asian", "Asian"],
    ["Black or African American", "Black or African American"],
    ["Native Hawaiian or Other Pacific Islander", "Native Hawaiian or Other Pacific Islander"],
    ["White", "White"],
    ["Other Race", "Other Race"],
    ["Asked but Unknown", "Asked but Unknown"],
    ["Unknown", "Unknown"]
];

export const FHIR_PHONE_TYPE_OPTIONS: IntegrationPatientField["options"] = [
    ["Home / Personal", "Home"],
    ["Work / Office", "Work"],
    ["Mobile", "Mobile"],
    ["Old - No Longer in Use", "Old"],
    ["Temporary", "Temp"]
];

export const ADD_PATIENT_FORM_OVERRIDES: Partial<Record<string, Record<string, IntegrationPatientField>>> = {
    "Aprima V2": {
        raceCategory: { key: "raceCategory", controlType: "select", options: APRIMA_RACE_OPTIONS },
        phoneType: { key: "phoneType", controlType: "select", options: APRIMA_PHONE_TYPE_OPTIONS }
    },
    EMA: {
        gender: { key: "gender", controlType: "select", options: FHIR_GENDER_OPTIONS },
        raceCategory: { key: "raceCategory", controlType: "select", options: FHIR_RACE_CATEGORY_OPTIONS },
        phoneType: { key: "phoneType", controlType: "select", options: FHIR_PHONE_TYPE_OPTIONS }
    },
    Eyefinity: {
        gender: { key: "gender", controlType: "select", options: FHIR_GENDER_OPTIONS },
        raceCategory: { key: "raceCategory", controlType: "select", options: FHIR_RACE_CATEGORY_OPTIONS },
        phoneType: { key: "phoneType", controlType: "select", options: FHIR_PHONE_TYPE_OPTIONS }
    }
};
