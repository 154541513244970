import uiRouter from "@uirouter/angularjs";
import angulartics from "angulartics";
import angularticsGoogle from "angulartics-google-tag-manager";

import { AppInsightsService } from "../common/services/app-insights.service";
import { TemplatesModule } from "../common/templates.module";
import { IntercomService } from "../intercom";
import { datePickerConfig } from "./datepicker.config";
import { httpConfig } from "./http.config";
import { idleConfig, idleRun } from "./idle.config";
import { intercomConfig } from "./intercom.config";
import { organizationSettings } from "./organization-settings.constants";
import { planUpgradePrompts } from "./plan-upgrade-prompts.constants";
import { routeAuthConfig } from "./route.auth.config";
import { routeConfig, routeRun } from "./route.config";
import { routeSubscriptionConfig } from "./route.subscription.config";
import { tooltips } from "./tooltips.constants";
import { timeConfig } from "./z.time.config";

export const CoreModule = angular
    .module("kno2.core", [
        "ngIdle",
        "ngFileUpload",
        "jcs.angular-http-batch",
        "ui.bootstrap",
        uiRouter,
        angulartics,
        angularticsGoogle,
        TemplatesModule,
        "kno2.factories",
        "kno2.services"
    ])
    .service("IntercomService", IntercomService)
    .config([
        "$compileProvider",
        "$sceDelegateProvider",
        "appConfig",
        ($compileProvider, $sceDelegateProvider, appConfig) => {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|javascript|data):/);
            $sceDelegateProvider.resourceUrlWhitelist(["self", `${appConfig.baseApiUrl}/**`]);
        }
    ])
    //default client route & route authorization
    .run([
        "$rootScope",
        "$uibModalStack",
        "$transitions",
        "$window",
        "$injector",
        "ExportService",
        "CacheService",
        "Auth0Service",
        ($rootScope, $uibModalStack, $transitions, $window, $injector, ExportService, CacheService, Auth0Service) => {
            // Added Window Language Data to the RootScope
            if ($injector.has("language")) $rootScope.language = $injector.get("language");

            $transitions.onStart({}, () => {
                // Force left menu refresh

                $uibModalStack.dismissAll("$transitions.onStart");
            });

            $rootScope.$on("notificationAlertVisible", () => {
                $rootScope.notificationAlert = true;
            });

            $rootScope.$on("notificationAlertHidden", () => {
                $rootScope.notificationAlert = false;
            });

            // Register Interoperability Service (Web Worker)
            ExportService.register();

            // Cache Service
            CacheService.register(CacheService.cacheNameL1);
            CacheService.register(CacheService.cacheNameL2);
            CacheService.clearAll();

            // Centralized logoff
            $rootScope.logoff = () => {
                angular.element($window).off("beforeunload"); // Clear any beforeunload handlers
                CacheService.clearAll(); // Clear cache before logoff.

                Auth0Service.logout();
            };
        }
    ])
    .config(httpConfig)
    .config(routeConfig)
    .run(routeRun)
    .config(datePickerConfig)
    .config(idleConfig)
    .run(idleRun)
    .run(intercomConfig)
    .run(routeAuthConfig)
    .run(routeSubscriptionConfig)
    .run(timeConfig)
    .service("appInsightsService", AppInsightsService)
    .constant("tooltips", tooltips)
    .constant("planUpgradePrompts", planUpgradePrompts)
    .constant("organizationSettings", organizationSettings).name;
