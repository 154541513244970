import { Component, inject, output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { DownloadsService } from "@kno2/data-access/downloads";
import { PopoverDirective } from "@kno2/shared/directives";
import { TOOLTIP_DOWNLOADS } from "../support.constants";

@Component({
    selector: "kno2-support-downloads",
    standalone: true,
    imports: [PopoverDirective],
    templateUrl: "./support.downloads.component.html"
})
export class SupportDownloadsComponent {
    private readonly route = inject(ActivatedRoute);
    private readonly downloadsService = inject(DownloadsService);
    private readonly upgrade = inject(UpgradeModule);
    private readonly appConfig = this.upgrade.$injector.get("appConfig");

    protected readonly baseUrl = this.appConfig.baseApiUrl;
    protected readonly tooltip = TOOLTIP_DOWNLOADS;
    protected readonly toggleCollapse = output<void>();

    protected expanded: boolean;
    protected downloads = this.downloadsService.getAll();

    public ngOnInit(): void {
        this.expanded = this.route.snapshot.routeConfig.path.endsWith("downloads");
    }
}
