import { NgClass } from "@angular/common";
import { Component, input } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CapabilityProperty, IntegrationPatient } from "@kno2/data-access/integrations";
import { CamelCaseToTitleCasePipe } from "@kno2/shared/pipes";
import { FormDateInputComponent, FormInputComponent, FormSelectComponent } from "@kno2/shared/ui";
import { requiredIfAnyValidator } from "@kno2/shared/validators";
import { NgxMaskDirective } from "ngx-mask";
import { ADD_PATIENT_FORM_OVERRIDES, DEFAULT_ADD_PATIENT_FORM } from "../patient-add-modal/patient-add-form";
import { IntegrationPatientField } from "./integration-patient-field";

@Component({
    selector: "kno2-integration-patient-dynamic-form",
    standalone: true,
    imports: [ReactiveFormsModule, FormInputComponent, FormSelectComponent, FormDateInputComponent, NgClass, CamelCaseToTitleCasePipe, NgxMaskDirective],
    templateUrl: "./integration-patient-dynamic-form.component.html"
})
export class IntegrationPatientDynamicFormComponent {
    public formGroup = input<FormGroup>();
    public integrationName = input<string>();
    public capabilityProperties = input<CapabilityProperty[]>();
    public patient = input<IntegrationPatient>();

    public formFields: IntegrationPatientField[][] = [];
    public hasSubmitted = false;
    public isSubmitting = false;

    public ngOnInit(): void {
        this.setupForm();
        this.configureValidators();
    }

    private setupForm(): void {
        const capabilityPropertiesMap = this.capabilityProperties().reduce((map, prop) => ({ ...map, [prop.name]: prop }), {});
        DEFAULT_ADD_PATIENT_FORM.forEach((row) => {
            const integrationFormFields: IntegrationPatientField[] = row.reduce((acc, field) => {
                const capabilityProperty = capabilityPropertiesMap[field.key];
                if (!capabilityProperty) return acc;

                const fieldOverride = ADD_PATIENT_FORM_OVERRIDES[this.integrationName()]?.[field.key];
                const updatedField = { ...field, ...fieldOverride };

                const control = new FormControl(this.patient()[updatedField.key], capabilityProperty.required && [Validators.required]);
                control.addValidators(updatedField.validators);

                if (updatedField.transformer) control.setValue(updatedField.transformer(this.patient()[updatedField.key]));

                this.formGroup().addControl(updatedField.key, control);

                acc.push(updatedField);
                return acc;
            }, [] as IntegrationPatientField[]);

            if (integrationFormFields.length) this.formFields.push(integrationFormFields);
        });
    }

    public configureValidators(): void {
        this.formGroup().addValidators([
            requiredIfAnyValidator("addressUse", "addressType", "streetAddress1"),
            requiredIfAnyValidator("emailType", "email"),
            requiredIfAnyValidator("phoneType", "telephone")
        ]);
    }
}
