import { Provider } from "@angular/core";
import { LocalStorageService } from "@kno2/shared/services";

export function provideLocalStorage(options: { keyPrefix: string }): Provider[] {
    return [
        {
            provide: LocalStorageService,
            useFactory: () => new LocalStorageService(options.keyPrefix),
            deps: []
        }
    ];
}
