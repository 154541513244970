import { inject, Injectable } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { LocalStorageService } from "../local-storage/local-storage.service";

@Injectable({
    providedIn: "root"
})
export class RedirectService {
    private readonly redirectUrlKey = "redirectUrl";
    private readonly upgrade = inject(UpgradeModule);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly sessionService = this.upgrade.$injector.get("SessionService");

    public isValidRedirectUrl(redirectUrl: string): boolean {
        const invalidUrls = [
            "/unauthorized",
            "/access-denied",
            "/auth-error",
            "/callback",
            "/account/login",
            "/account/register",
            "/account/logout",
            "/?email="
        ];

        const isValidUrl = !invalidUrls.some((url) => redirectUrl.startsWith(url) || redirectUrl === url);

        return isValidUrl;
    }

    public async setRedirectUrl(redirectUrl: string): Promise<void> {
        if (this.isValidRedirectUrl(redirectUrl)) {
            this.localStorageService.set(this.redirectUrlKey, redirectUrl);
        }
    }

    public getRedirectUrl(remove = false): string {
        let redirectUrl = this.localStorageService.get(this.redirectUrlKey);

        if (redirectUrl) return redirectUrl as string;

        if (this.sessionService.isSysAdmin()) redirectUrl = "/dashboard/messaging";
        else if (this.sessionService.userInRole("Administrator")) redirectUrl = "/account/dashboard";
        else if (this.sessionService.userInRole("User")) redirectUrl = "/intake";

        if (remove) this.localStorageService.remove(this.redirectUrlKey);

        return redirectUrl as string;
    }
}
