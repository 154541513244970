import { Component, inject, output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { PopoverDirective } from "@kno2/shared/directives";
import { TOOLTIP_KNOWLEDGE_BASE } from "../support.constants";

@Component({
    selector: "kno2-support-knowledge-base",
    standalone: true,
    imports: [PopoverDirective],
    templateUrl: "./support.knowledge-base.component.html"
})
export class SupportKnowledgeBaseComponent {
    private readonly route = inject(ActivatedRoute);
    private readonly upgrade = inject(UpgradeModule);
    private readonly appConfig = this.upgrade.$injector.get("appConfig");

    protected readonly tooltip = TOOLTIP_KNOWLEDGE_BASE;
    protected expanded: boolean;
    protected brandDisplayName: string;
    protected knowledgeBaseUrl: string;
    protected readonly toggleCollapse = output<void>();

    public ngOnInit(): void {
        this.expanded = this.route.snapshot.routeConfig.path.endsWith("knowledge");
        this.brandDisplayName = this.appConfig.brandDisplayName;
        this.knowledgeBaseUrl = this.appConfig.knowledgeBaseUrl;
    }
}
