import { Component, inject, input, output } from "@angular/core";
import { IntegrationsService } from "@kno2/data-access/integrations";
import { ExternalLoginToken } from "@kno2/data-access/users";
import { NotificationService } from "@kno2/shared/ui";
import { ConfirmationDialogService } from "@kno2/shared/ui/confirmation-dialog";
import { noop } from "angular";

@Component({
    selector: "kno2-profile-integrations",
    standalone: true,
    imports: [],
    templateUrl: "./profile-integrations.component.html",
    styles: ``
})
export class ProfileIntegrationsComponent {
    private readonly integrationsService = inject(IntegrationsService);
    private readonly confirmationDialogService = inject(ConfirmationDialogService);
    private readonly notificationService = inject(NotificationService);

    public externalLoginTokens = input<ExternalLoginToken[]>([]);
    public onRevokeToken = output<void>();

    public async clearCachedData(): Promise<void> {
        const result = await this.confirmationDialogService
            .open({
                bodyText: "Are you sure you want to clear your Integration Cached Data?",
                okText: "Clear"
            })
            .result.catch(noop);

        if (!result) return;

        try {
            await this.integrationsService.clearCachedData();
            this.notificationService.success("Cleared cached data.");
        } catch (err) {
            this.notificationService.error("There was an error clearing the cached data.");
        }
    }

    public async revokeToken(token): Promise<void> {
        const result = await this.confirmationDialogService
            .open({
                bodyText: "Are you sure you want to revoke token access?",
                okText: "Revoke"
            })
            .result.catch(noop);

        if (!result) return;

        try {
            const revokeFn = token.name === "PointClickCare" ? () => this.integrationsService.revokeToken(token) : this.integrationsService.revokeTokenOauth;
            await revokeFn();
            this.onRevokeToken.emit();
        } catch (err) {
            this.notificationService.error("There was an error revoking the token.");
        }
    }
}
