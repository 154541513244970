angular.module("kno2.settings").controller("SubscriptionDowngradeModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "plan",
    function ($scope, $uibModalInstance, plan) {
        $scope.plan = plan;
        $scope.closeModal = function () {
            $uibModalInstance.dismiss("cancel");
        };

        $scope.downgradeSubscription = function () {
            $uibModalInstance.close($scope.plan.downgradePlanId);
        };
    }
]);
