import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { RedirectService } from "@kno2/shared/services";
import { AuthService } from "../services";
import { WINDOW_TOKEN } from "../tokens";

export const authGuard: CanActivateFn = async (route, state) => {
    const window = inject(WINDOW_TOKEN);
    const authService = inject(AuthService);
    const router = inject(Router);
    const redirectService = inject(RedirectService);
    const isAuthenticated = await authService.isAuthenticated();

    if (!isAuthenticated) {
        const redirectUrl = window.location.pathname + window.location.search;

        redirectService.setRedirectUrl(redirectUrl);
        return router.parseUrl("/account/login");
    }

    return true;
};
