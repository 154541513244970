import { Component, OnDestroy, effect, inject } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { RouterOutlet } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { WINDOW_TOKEN } from "@kno2/core/tokens";
import { SocketService, TooltipsService } from "@kno2/shared/services";

@Component({
    imports: [RouterOutlet],
    selector: "app-root",
    templateUrl: "./app.component.html",
    standalone: true
})
export class AppComponent implements OnDestroy {
    private readonly window = inject(WINDOW_TOKEN);
    private readonly socketService = inject(SocketService);
    private readonly tooltipsService = inject(TooltipsService);
    private readonly authService = inject(AuthService);

    private isAuthenticated = toSignal(this.authService.isAuthenticated$);

    public constructor() {
        effect(() => {
            if (this.isAuthenticated()) this.socketService.init();
        });
    }

    public async ngOnInit(): Promise<void> {
        try {
            this.tooltipsService.init();

            this.window.onbeforeunload = () => this.ngOnDestroy();
        } catch (err) {
            console.error(err);
        }
    }

    public ngOnDestroy(): void {
        this.window.onbeforeunload = null;
        this.socketService?.disconnect();
    }
}
