import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../../core/services/auth.service";

@Component({
    selector: "kno2-auth-login",
    standalone: true,
    template: ``
})
export class LoginComponent implements OnInit {
    private readonly route = inject(ActivatedRoute);
    private readonly authService = inject(AuthService);

    public async ngOnInit(): Promise<void> {
        const loginType = this.route.snapshot.paramMap.get("loginType");
        const connection = loginType === "pcc" ? "PointClickCare" : loginType;
        const authParams = connection ? { connection } : null;

        await this.authService.login(authParams);
    }
}
