import { HttpEvent, HttpHandlerFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

export function authInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const authService = inject(AuthService);

    if (!request.url.includes("/api")) return next(request);

    const accessToken = authService.accessToken;

    return next(
        request.clone({
            setHeaders: {
                ["Authorization"]: `Bearer ${accessToken}`
            }
        })
    );
}
