import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { AuthService } from "../services";

export const profileGuard: CanActivateFn = async (route, state) => {
    const upgrade = inject(UpgradeModule);
    const authService = inject(AuthService);
    const sessionService = upgrade.$injector.get("SessionService");
    const profile = sessionService.getProfile();

    if (!Object.keys(profile)?.length) {
        if (!authService.accessToken) {
            await authService.renewTokens();
        }
        await sessionService.refreshProfile();
    }

    return true;
};
