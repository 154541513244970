ReactivateOrgModalCtrl.$inject = [
    "$scope",
    "$rootScope",
    "$uibModalInstance",
    "$timeout",
    "SessionService",
    "NotificationService",
    "SubscriptionsFactory",
    "currentPlan"
];
export function ReactivateOrgModalCtrl(
    $scope,
    $rootScope,
    $uibModalInstance,
    $timeout,
    sessionService,
    notificationService,
    subscriptionsFactory,
    currentPlan
) {
    $scope.forms = {};
    $scope.userProfile = sessionService.getProfile();
    $scope.newPlan = {};
    $scope.fastspringSubmitted = false;

    var initSubscriptions = function () {
        subscriptionsFactory.get().then(function (res) {
            $scope.plan = res.data;
            if ($scope.plan.planId === null) {
                $scope.plan.planId = "-1";
            }
            $scope.plan.upgradePlanId = $scope.plan.planId;
            $scope.plan.originalPlanId = $scope.plan.planId;
        });
    };

    $scope.resetPlanSelection = function () {
        $scope.fastspringSubmitted = true;
        $scope.verifyPayment(true);
    };

    $scope.verifyPayment = function (keepLooping) {
        subscriptionsFactory.get().then(function (res) {
            $scope.confirmedPlan = res.data;
            if ($scope.confirmedPlan.planId == $scope.plan.upgradePlanId) {
                $uibModalInstance.close();
            } else {
                if (keepLooping) {
                    $timeout(
                        function () {
                            $scope.verifyPayment(true);
                        },
                        1500,
                        false
                    );
                } else {
                    notificationService.error("Unable to verify payment was completed");
                }
            }
        });
    };

    $scope.planChanged = function () {
        $scope.plan.upgradePlanId = $scope.plan.planId;

        var upgradePlan = parseInt($scope.plan.upgradePlanId, 10);
        var newPlan = _.find($scope.plan.plans, { id: upgradePlan });

        $scope.newPlan.fastSpringUrl = $scope.plan.fastSpringUrl + newPlan.urlPathName + $scope.plan.fastSpringParams;
        $scope.newPlan.urlPathName = "/" + newPlan.urlPathName;
        $scope.newPlan.referrer = $scope.plan.orgId + "#" + upgradePlan + "#" + $scope.userProfile.fastSpringDomain;

        document.fastSpringReactivateForm.action = $scope.newPlan.fastSpringUrl;
    };

    $scope.sendToFastspring = function () {
        return !$scope.fastspringSubmitted && $scope.plan && $scope.plan.upgradePlanId != "-1";
    };
    $scope.reactivateOrganization = function () {
        if ($scope.plan.upgradePlanId != null) {
            var upgradePlan = parseInt($scope.plan.upgradePlanId, 10);
            var newPlan = _.find($scope.plan.plans, { id: upgradePlan });
            if (newPlan) {
                // Validate number of users before update
                if (newPlan.allowedUsers != -1 && newPlan.allowedUsers < $scope.plan.totalUsersCount) {
                    notificationService.error(
                        stringFormat(
                            "Selected subscription plan allows a maximum of {0} users and you currently have {1} users defined. Please select a valid subscription plan to match the actual utilization.",
                            newPlan.allowedUsers,
                            $scope.plan.totalUsersCount
                        )
                    );
                } else {
                    // Update Subscription
                    subscriptionsFactory
                        .reactivate($scope.plan.upgradePlanId)
                        .then(function () {
                            $uibModalInstance.close();
                        })
                        .catch(function () {
                            notificationService.error("There was an error reactivating your organization.");
                        });
                }
            }
        } else {
            subscriptionsFactory
                .reactivate($scope.plan.upgradePlanId)
                .then(function () {
                    $uibModalInstance.close();
                })
                .catch(function () {
                    notificationService.error("There was an error reactivating your organization.");
                });
        }
    };

    $scope.cancelReactivate = function () {
        if (!$scope.userProfile.orgIsCancelled && $scope.userProfile.orgIsInGracePeriod) {
            window.history.back();
        } else {
            $rootScope.logoff();
        }
    };

    (function () {
        $scope.currentPlan = currentPlan;
        $scope.allowAllSubscriptions = true; // Enabled all subscriptions selection from template.
        initSubscriptions();
    })();
}
