import { ApplicationInsights, IApplicationInsights } from "@microsoft/applicationinsights-web";
declare const BUILD_NUMBER: string;

export class AppInsightsService {
    public static $inject: Array<string> = ["appConfig"];
    private readonly buildNumber: string = BUILD_NUMBER;
    private readonly appInsights: IApplicationInsights;

    constructor(appConfig) {
        if (appConfig.appInsightsInstrumentationKey) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: appConfig.appInsightsInstrumentationKey,
                    enableAutoRouteTracking: true
                }
            });
            this.appInsights = appInsights.loadAppInsights();
        }
    }

    public updateProfile(userId: string, organizationId: string): void {
        if (!this.appInsights) return;
        try {
            if (userId && organizationId) {
                this.appInsights.context.user.id = userId;
                this.setAppInsightsCustomFields(organizationId);
            }
        } catch (error) {
            console.error(error);
        }
    }

    private setAppInsightsCustomFields(organizationId: string): void {
        if (!this.appInsights) return;
        const telemetryInitializer = (envelope) => {
            envelope.data.organizationId = organizationId;
            envelope.data.buildNumber = this.buildNumber;
        };
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }
}
