import { Directive, TemplateRef, ViewContainerRef, inject, input } from "@angular/core";
import { UpgradeModule } from "@angular/upgrade/static";
import { NetworksService } from "@kno2/data-access/networks";

@Directive({
    selector: "[kno2NetworkFeatureToggle]",
    standalone: true
})
export class NetworkFeatureToggle {
    private readonly upgrade = inject(UpgradeModule);
    private readonly sessionService = this.upgrade.$injector.get("SessionService");
    private templateRef = inject(TemplateRef);
    private viewContainer = inject(ViewContainerRef);
    private networkFeaturesService = inject(NetworksService);

    protected requiredFeatures = input.required<string[]>({ alias: "kno2NetworkFeatureToggle" });

    public async ngOnInit(): Promise<void> {
        const { administerOfNetworkObjectId } = this.sessionService.getProfile();

        const featureStatuses = await this.networkFeaturesService.getNetworkFeatureStatuses(administerOfNetworkObjectId);
        const enabledFeatureNames = featureStatuses
            .flatMap((f) => [f, ...(f.childNetworkFeatures || [])])
            .filter((f) => f.isEnabled)
            .map((f) => f.name);

        if (this.requiredFeatures().every((f) => enabledFeatureNames.includes(f))) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
