import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "camelCaseToTitleCase",
    standalone: true
})
export class CamelCaseToTitleCasePipe implements PipeTransform {
    public transform(value: string): string {
        return value
            .replace(/([A-Z]|\d+)/g, " $1")
            .replace(/^./, (str) => str.toUpperCase())
            .trim();
    }
}
