import { inject } from "@angular/core";
import { ResolveFn } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";

export const profileResolver: ResolveFn<Promise<any>> = async (route) => {
    const upgrade = inject(UpgradeModule);
    const sessionService = upgrade.$injector.get("SessionService");
    let profile = sessionService.getProfile();

    if (!Object.keys(profile).length) {
        await sessionService.refreshProfile();
        profile = sessionService.getProfile();
    }

    return profile;
};
