import { filterNonEmptyProperties } from "@kno2/shared/utils";

declare var $: any;

export class AdminDocumentSourcesService {
    public static $inject: Array<string> = ["$http"];

    constructor(private $http) {}

    getPagedItemsSelectValue() {
        return $("#pagedItemsSelect").val();
    }

    getDocumentSources(sortDir, pageNumber, pageSize, search, type, organizationId) {
        if (!pageSize) pageSize = 10;
        if (!pageNumber) pageNumber = 1;
        if (!type) type = "DirectMessage";
        const params = {
            sortDir,
            pageNumber,
            pageSize,
            type,
            search,
            organizationId
        };
        let promise = this.$http
            .get("/api/admin/documentsources", {
                params: filterNonEmptyProperties(params)
            })
            .then(function (response) {
                return response.data;
            });
        return promise;
    }

    getDocumentSource(id) {
        let promise = this.$http.get("/api/admin/documentsources/" + id).then(function (response) {
            return response.data;
        });
        return promise;
    }

    getDocumentSourceStatuses() {
        let promise = this.$http.get("/api/admin/documentsources/statuses").then(function (response) {
            return response.data;
        });
        return promise;
    }

    setDocumentSourceStatus(id, status, updateSureScripts) {
        const resource = { status: status, updateSureScripts: updateSureScripts };
        return this.$http.post(`/api/admin/documentsources/${id}/status`, resource);
    }
}
