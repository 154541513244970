<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="cancel()">
        &times;
    </button>
    <h2 class="modal-title">Confirm</h2>
</div>
<div
    class="modal-body"
    [innerHTML]="bodyText"></div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-default"
        (click)="cancel()">
        {{ cancelText }}
    </button>
    <button
        class="btn btn-primary"
        (click)="confirm()"
        [disabled]="isConfirmInProgress">
        @if (isConfirmInProgress) {
            <span class="spinner-border spinner-border-sm"></span>
        }
        {{ okText }}
    </button>
</div>
