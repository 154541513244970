import { Component, inject, input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { AppConfigToken } from "@kno2/interop";
import { FeatureToggleDirective, NetworkFeatureToggle } from "@kno2/shared/directives";

@Component({
    selector: "kno2-settings-menu",
    standalone: true,
    imports: [FeatureToggleDirective, NetworkFeatureToggle, RouterLink],
    templateUrl: "./settings-menu.component.html"
})
export class SettingsMenuComponent {
    private readonly appConfig = inject(AppConfigToken);

    protected currentUser = input.required<{ isNetworkAdmin: string; resellerType: string }>();

    protected isPrivateLabel = false;
    protected isLicensedOrg = false;

    public ngOnInit(): void {
        this.isPrivateLabel = this.appConfig.brand !== "Kno2";
        this.isLicensedOrg = this.currentUser().resellerType === "Licensed";
    }
}
