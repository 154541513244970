import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { UpgradeModule } from "@angular/upgrade/static";
import { WINDOW_TOKEN } from "../tokens";

export const rootGuard: CanActivateFn = async (route, state) => {
    const router = inject(Router);
    const window = inject(WINDOW_TOKEN);
    const upgrade = inject(UpgradeModule);
    const sessionService = upgrade.$injector.get("SessionService");

    const baseHref = "/";

    if (state.url !== baseHref) return true;
    if (sessionService.userInRole("User")) return router.parseUrl("/intake");
    if (sessionService.userInRole("Administrator")) return router.parseUrl("/account/dashboard");
    if (sessionService.isSysAdmin()) window.location.href = "/admin/dashboard/messaging";
    return false;
};
