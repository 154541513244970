type SsoFeature = "PointClickCareIdentityProvider" | "AzureADIdentityProvider" | "OktaIdentityProvider";

export class SingleSignOnService {
    public static $inject: Array<string> = ["FeatureService"];

    constructor(private FeatureService) {}

    public isOrgSSOEnabled(): boolean {
        const ssoFeatures: Array<SsoFeature> = ["PointClickCareIdentityProvider", "AzureADIdentityProvider", "OktaIdentityProvider"];
        return this.FeatureService.isAnyEnabled(ssoFeatures);
    }
}
