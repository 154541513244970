import { Component, ElementRef, Injectable, Input, input, ViewChild } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbDatepickerConfig, NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { DateFormatDirective } from "../../../directives/date-format/date-format.directive";
import { DateTime } from "luxon";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = "/";
    constructor(private config: NgbDatepickerConfig) {
        super();
    }

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[1], 10),
                month: parseInt(date[0], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        if (!date) return "";

        const now = DateTime.local();
        if (date.year < 100) date.year += 2000;
        if (DateTime.fromObject(date) > now) date.year -= 100;

        let dt = DateTime.fromObject({ day: date.day, month: date.month, year: date.year });

        return dt.toFormat("MM/dd/yyyy");
    }
}

@Component({
    selector: "kno2-form-date-input",
    standalone: true,
    imports: [ReactiveFormsModule, NgbDatepickerModule, NgxMaskDirective, DateFormatDirective],
    providers: [provideNgxMask(), { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }],
    templateUrl: "./form-date-input.component.html"
})
export class FormDateInputComponent {
    public control = input.required<FormControl>();
    public hasError = input<boolean>(false);
    public placeholder = input<string>();
    public mask = input<string>("00/00/0000");
    public config = input<NgbDatepickerConfig>();

    @ViewChild("inputField") public inputField: ElementRef<HTMLInputElement>;
    @ViewChild("datepicker") public datepicker: ElementRef<HTMLDivElement>;

    constructor(
        private dateAdapter: NgbDateAdapter<string>,
        private datePickerConfig: NgbDatepickerConfig
    ) {
        const { year, month, day } = DateTime.local();
        datePickerConfig.minDate = this.config()?.minDate || { year: 1900, month: 1, day: 1 };
        datePickerConfig.maxDate = this.config()?.maxDate || { year, month, day };
    }

    public formatOnPaste($event) {
        const data = $event.clipboardData.getData("text");
        let [month, day, year] = data.split(/\D+/).filter(Boolean);

        month = month?.padStart(2, "0");
        day = day?.padStart(2, "0");
        year = year?.substring(0, 4);

        let result = [month, day, year].join("");
        this.control().setValue(result);
    }

    public preventPickerFocus() {
        setTimeout(() => this.inputField.nativeElement.focus(), 0);
    }
}
