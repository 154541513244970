angular.module("kno2.settings").controller("SettingsReportsCtrl", [
    "$scope",
    "$http",
    "$uibModal",
    "SessionService",
    "NotificationService",
    function ($scope, $http, $uibModal, SessionService, NotificationService) {
        (function () {})();
    }
]);
