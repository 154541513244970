import { CommonModule } from "@angular/common";
import { Component, Signal, computed } from "@angular/core";
import { ContactUploadLatestData, ContactsService } from "@kno2/data-access/contacts";
import { SocketService } from "@kno2/shared/services";
import { ContactsUploadCardComponent } from "./contacts-upload-card.component";
import { ContactsUploadFailureCardComponent } from "./contacts-upload-failure-card.component";
import { ContactsUploadSuccessCardComponent } from "./contacts-upload-success-card.component";

@Component({
    selector: "kno2-contacts",
    imports: [CommonModule, ContactsUploadCardComponent, ContactsUploadSuccessCardComponent, ContactsUploadFailureCardComponent],
    templateUrl: "./contacts.component.html",
    standalone: true
})
export class ContactsComponent {
    public currentUpload: Signal<ContactUploadLatestData>;
    public lastUpload: Signal<ContactUploadLatestData>;
    public isUploading = false;
    public isDownloadingErrorFile = false;

    constructor(
        private contactsService: ContactsService,
        private socketService: SocketService
    ) {
        const latestMetadata = this.contactsService.latestMetadata();
        const { contactsUploadHasFinished } = this.socketService;
        this.currentUpload = computed(() => contactsUploadHasFinished()?.current || latestMetadata()?.current);
        this.lastUpload = computed(() => contactsUploadHasFinished()?.lastUpload || latestMetadata()?.lastUpload);
    }

    public async downloadContacts(): Promise<void> {
        await this.contactsService.downloadContacts();
    }

    public async downloadErrorFile(): Promise<void> {
        this.isDownloadingErrorFile = true;
        await this.contactsService.downloadErrorFile(this.lastUpload().contactUploadId);
        this.isDownloadingErrorFile = false;
    }

    public async uploadFile(files: Array<File>): Promise<void> {
        this.isUploading = true;
        await this.contactsService.uploadContacts(files[0]);
        this.isUploading = false;
    }
}
