angular.module("kno2.settings").controller("SubscriptionLicensedModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "SessionService",
    function ($scope, $uibModalInstance, sessionService) {
        $scope.userProfile = sessionService.getProfile();
        $scope.cancelModal = function () {
            $uibModalInstance.dismiss("cancel");
        };
    }
]);
