angular.module("kno2.settings").controller("SubscriptionUpgradeModalCtrl", [
    "$scope",
    "$uibModalInstance",
    "plan",
    "SessionService",
    "$timeout",
    function ($scope, $uibModalInstance, plan, SessionService, $timeout) {
        $scope.plan = plan;
        $scope.currentUser = SessionService.getProfile();

        var upgradePlan = parseInt($scope.plan.upgradePlanId, 10);
        var newPlan = _.find($scope.plan.plans, { id: upgradePlan });

        $scope.newPlan = {
            urlPathName: "/" + newPlan.urlPathName,
            fastSpringUrl: $scope.plan.fastSpringUrl + newPlan.urlPathName + $scope.plan.fastSpringParams,
            referrer: $scope.plan.orgId + "#" + upgradePlan + "#" + $scope.currentUser.fastSpringDomain
        };

        $timeout(function () {
            document.fastSpringUserUpgradeForm.action = $scope.newPlan.fastSpringUrl;
        }, 100);

        $scope.planChanged = function () {
            upgradePlan = parseInt($scope.plan.upgradePlanId, 10);
            newPlan = _.find($scope.plan.plans, { id: upgradePlan });

            $scope.newPlan.fastSpringUrl = $scope.plan.fastSpringUrl + newPlan.urlPathName + $scope.plan.fastSpringParams;
            $scope.newPlan.urlPathName = "/" + newPlan.urlPathName;
            $scope.newPlan.referrer = $scope.plan.orgId + "#" + upgradePlan + "#" + $scope.currentUser.fastSpringDomain;

            document.fastSpringUserUpgradeForm.action = $scope.newPlan.fastSpringUrl;
        };

        $scope.cancelModal = function () {
            $uibModalInstance.dismiss("cancel");
        };

        $scope.updateSubscription = function () {
            $uibModalInstance.close($scope.plan.upgradePlanId);
        };
    }
]);
